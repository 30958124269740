<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Inquérito de Satisfação - Suporte</h1>
    <p><b>Pat: </b>{{ pat }}</p>
    <p><b>Nome: </b>{{ name }}</p>
    <p><b>Título: </b>{{ resume }}</p>
    <p><b>Problema: </b>{{ problem }}</p>
    <p><b>Vendedor: </b>{{ seller }}</p>
    <Divider />
    <form
      name="SatisfactionFormFnstallationsQuiz"
      class="p-col-12"
      @submit.prevent="saveExpenditure"
      style="min-height: 50vh"
    >
      <div v-for="quiz in quizzes" :key="quiz.quiz_id">
        <div
          v-for="question in quiz.questions"
          :key="question.question_id"
          class="p-fluid p-formgrid p-grid"
        >
          <div class="p-field p-md-12">
            <b>{{ question.question }}</b>
          </div>
          <small
            v-if="!question.answer_is_text && submited && !question.select"
            class="p-error p-ml-2"
            role="alert"
          >
            Selectione uma das opções
          </small>
          <div class="p-field p-md-12">
            <div v-if="question.answer_is_text">
              <Textarea
                v-model="question.select"
                :autoResize="true"
                v-bind:class="['p-inputtextarea', 'p-inputtext', 'p-component']"
                rows="5"
              />
            </div>
            <div
              v-else
              v-for="options of quiz.questions_options"
              :key="options.option_id"
              class="p-field-radiobutton p-d-inline p-mr-3"
            >
              <RadioButton
                :id="question.question_id + '_' + options.option_id"
                :value="options.option_id"
                v-model="question.select"
              />
              <label :for="question.question_id + '_' + options.option_id">{{
                options.option_label
              }}</label>
            </div>
          </div>
          <Divider />
        </div>
      </div>
      <Button
        icon="pi pi-check"
        label="Guardar"
        class="p-button-success p-mr-1"
        @click="submitForm()"
      />
      <Button
        label="Fechar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="goList()"
      />
    </form>
  </div>
</template>

<script>
import satisfactionFormService from "../services/satisfactionForm.service";

export default {
  name: "SatisfactionFormFnstallationsQuiz",
  data() {
    return {
      loading: true,
      submited: false,
      hasErrors: false,
      isAnswered: false,
      pat: "",
      name: "",
      problem: "",
      seller: "",
      resume: "",
      quizzes: [],
    };
  },
  created() {
    this.loading = true;
    this.getForms();
  },
  methods: {
    getForms() {
      this.loading = true;
      satisfactionFormService
        .getSupportForm(this.$route.params.id)
        .then((response) => {
          this.loading = false;
          this.pat = response.id;
          this.name = response.name;
          this.problem = response.problem;
          this.seller = response.seller;
          this.resume = response.resume;
          this.isAnswered = response.is_answered ;
          this.quizzes = response.quizzes;
        });
    },
    submitForm() {
      this.submited = true;
      let bodyParams = { quiz: [] };
      this.quizzes.forEach((quiz) => {
        this.hasErrors = quiz.questions.find(
          (question) => !question.answer_is_text && !question.select
        );
        if (this.hasErrors) {
          return;
        }

        let questAnswer = {
          quiz_id: quiz.quiz_id,
          answeres: [],
        };
        quiz.questions.map((quest) => {
          questAnswer.answeres.push({
            question_id: parseInt(quest.question_id),
            is_text: quest.answer_is_text ? parseInt(quest.answer_is_text) : 0,
            value: quest.select,
          });
        });
        bodyParams.quiz.push(questAnswer);

        return satisfactionFormService
          .addSupportAnswer(this.$route.params.id, bodyParams)
          .then((response) => {
            this.loading = false;
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro gravar o Inquérito de Satisfação",
                life: 3000,
              });
            }

            this.isAnswered = true;

            return this.$toast.add({
              severity: "success",
              summary: "Inquérito de Satisfação",
              detail: "Inquérito de Satisfação gravado com sucesso",
              life: 3000,
            });
          });
      });
    },
    goList() {
      this.$router.go(-1);
    },
  },
};
</script>
